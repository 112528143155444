<template>
  <page-content :show-search="true">
    <template #search>
      <table-search
        ref="search"
        :fields="search_fields"
        :default="search_default"
        @runSearch="runSearch"
        @changeField="searchChangeField"
      />
    </template>

    <template #default>
      <table-header
        ref="header"
        :title="title"
        :button-actions="header_actions"
        @runSearch="runSearch"
        :search-tips="$t('kiosk.package_search_tips')"
      />

      <table-content
        :per-page="page_condition.pageSize"
        :per-page-dropdown-enabled="true"
        @perPageChange="perPageChange"
        :columns="columns"
        :rows="rows"
        :total-rows="total_rows"
        :current-page="current_page"
        :action-options="table_actions"
        @runTableAction="runTableAction"
        @sortChange="sortChange"
        @pageChange="pageChange"
        :permission="view_permission"
      />
    </template>

  </page-content>
</template>

<script>
import common from '@/common'
import TableContent from '@/components/TableContent'
import TableHeader from '@/components/TableHeader'
import TableSearch from '@/components/TableSearch'
import PageContent from '@/components/PageContent'
import kioskUtils from '@/libs/kiosk-utils'

export default {
  components: {
    PageContent,
    TableSearch,
    TableHeader,
    TableContent,
  },
  name: 'kioskManagePackage',
  data() {
    return {
      title: common.getMenuName('kioskManagePackage'),
      view_permission: common.checkPermission('Kiosk_Menu_Installer'),
      // view_permission: true,
      rows: [],
      total_rows: 0,
      sort_condition: {},
      current_page: 1,
      page_condition: {
        pageIndex: 0,
        pageSize: common.getTablePerPage()
      },
      status_map: [],
      header_actions: [
        {
          text: 'common.add',
          variant: 'primary',
          fun: this.addData,
          permission: 'Kiosk_Menu_Installer'
        },
      ],
      columns: [
        {
          label: 'common.row_index',
          field: 'row_index',
          sortable: false,
          width: '50px',
        },
        {
          label: 'kiosk.package_name',
          field: 'packageName',
          width: '280px',
        },
        {
          label: 'kiosk.package_version',
          field: 'version',
          width: '90px',
        },
        {
          label: 'kiosk.package_remark',
          field: 'remark',
          // width: '190px',
        },
        {
          label: 'kiosk.device_platform',
          field: 'platform',
          width: '80px',
        },
        {
          label: 'kiosk.upload_time',
          field: 'uploadedTime',
          width: '190px',
          change: this.changeTimeFormat,
        },
        {
          label: 'common.action',
          field: 'action',
          width: '170px',
          sortable: false,
        },
      ],
      table_actions: [
        {
          text: 'common.edit',
          variant: 'primary',
          fun: 'editData',
          permission: 'Kiosk_Menu_Installer'
        },
        {
          text: 'common.delete',
          variant: 'danger',
          fun: 'deleteData',
          permission: 'Kiosk_Menu_Installer'
        }
      ],
      search_fields: [
        {
          field: 'platform',
          label: 'kiosk.device_platform',
          type: 'select',
          options: [],
          show: common.isAdmin(),
          clearable: true,
        },
      ],
      search_default: {
        platform: null,
      },
    }
  },
  mounted() {
    if (common.isAdmin()) {
      kioskUtils.getKioskEnvOptions().then(({ envOptions, platformOptions }) => {
        platformOptions.forEach(option => this.search_fields.find(field => field.field === 'platform').options.push(option))
      })
      this.getList()
    }
  },

  methods: {
    getList: async function () {
      if (!this.view_permission) return

      const api = '/kioskupdate/GetPackageList'
      const search = this.getSearchCondition()
      const url = common.getTableUrl(api, search, this.sort_condition, this.page_condition)
      const { data } = await common.apiGetData(url)

      // Assign value to current component
      this.total_rows = data.total
      this.rows = data.packages
    },
    perPageChange(size) {
      this.page_condition.pageIndex = 0
      this.page_condition.pageSize = size
      common.setTablePerPage(size)
      this.getList()
    },
    editData: function (data) {
      this.$router.push({
        name: 'kioskManagePackage_edit',
        params: { id: common.encrypt(data.packageID) }
      })
    },

    runSearch: function () {
      this.current_page = 1
      this.page_condition.pageIndex = 0
      const vmOfSearch = this.$refs.search
      common.syncSearchFields({
        companyID: vmOfSearch.list.companyID,
        facilityID: vmOfSearch.list.facilityID,
        floorID: vmOfSearch.list.floorID,
        roomID: vmOfSearch.list.roomID,
      })
      this.getList()
    },
    async searchChangeField(field, value) {
      await common.searchChangeField({
        fieldName: field,
        newValue: value,
        currentVm: this,
        searchVm: this.$refs.search,
        isSyncGlobally: false
      })
    },

    addData: function () {
      this.$router.push({ name: 'kioskManagePackage_add' })
    },
    deleteData: function (data) {
      common.showConfirm({
        title: `${this.$t('common.delete_tips')}?`,
        text: `${data.packageName} | ${data.version} | ${data.remark} | ${data.platform}`,
        confirm_fun: async () => {
          await this.$requestWehealth({
            url: '/kioskupdate/DeletePackage',
            method: 'post',
            params: {
              packageID: data.packageID,
            }
          })
          common.showToast({ title: this.$t('common.delete_success') })
          this.getList()
        }
      })
    },

    runTableAction: function (action, data) {
      this[action](data)
    },
    sortChange: function (value) {
      this.sort_condition = value
      this.getList()
    },
    getSearchCondition: function () {
      this.$refs.search.buildSearchCondition()

      let where = {}
      where['searchContent'] = this.$refs.header.searchContent
      where = Object.assign(where, this.$refs.search.search_condition)

      return where
    },
    pageChange: function (value) {
      this.current_page = value
      this.page_condition.pageIndex = value - 1
      this.getList()
    },
    changeStatus: function (value) {
      for (const i in this.status_map) {
        if (this.status_map[i].statusCode === value) {
          return this.status_map[i].statusDesc
        }
      }
    },
    changeTimeFormat(time) {
      if (time === null) {
        return ''
      } else {
        return this.$moment(time)
          .format('YYYY-MM-DD HH:mm:ss')
      }
    },
  }
}
</script>

<style lang="scss">

</style>
